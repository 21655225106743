import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import ReactGA from "react-ga";
import "./scss/style.scss";
import { Footer, Header, LinkServices } from "./container";
import { HomeRoutes, AreaRoutes, AreaTravelRoutes, HouseRoutes, ChatRoutes, AuthRoutes, HomePageRoutes, ProfileRoutes, RestaurantRoutes, HotelRoutes } from "./routes";

import '@coreui/coreui/dist/css/coreui.min.css';


import DefaultLayout from "./layout/DefaultLayout";
import Back from "./container/back/Back";
import FavoriteRoutes from "./routes/FavoriteRoutes";
const Home = React.lazy(() => import("./view/home/Home"));
const Area = React.lazy(() => import("./view/area/Area"));
const AreaDetails = React.lazy(() => import("./view/area/AreaDetails"));
const House = React.lazy(() => import("./view/house/House"));
const Chat = React.lazy(() => import("./view/chat/Chat"));
const Login = React.lazy(() => import("./view/auth/Login"));
const Register = React.lazy(() => import("./view/auth/Register"));

const TRACKING_ID = "G-ZY1P5ET0CC"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Header />
      {/* <div className="main-content">
      </div> */}
      <Suspense fallback={<div>Loading ...</div>}>
        <HomeRoutes />
        <HomePageRoutes />
        <ProfileRoutes />
        <AuthRoutes />
        <AreaRoutes />
        <AreaTravelRoutes />
        <RestaurantRoutes />
        <HotelRoutes />
        <HouseRoutes />
        <ChatRoutes />
        <FavoriteRoutes />
      </Suspense>
      {/* <LinkServices /> */}
    </BrowserRouter>
  );
}

export default App;
