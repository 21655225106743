import React, { CSSProperties, useEffect, useState, useRef } from "react";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, Form, Input, Select, notification } from 'antd'
import { IoCloseOutline } from "react-icons/io5";
import { Dropdown, Menu } from "antd";
import { MenuOutlined, FolderOutlined, LoginOutlined, UserOutlined, ArrowRightOutlined, CameraOutlined } from "@ant-design/icons";
import pippip from "../../asset/pippip.png";
import senbay from "../../asset/senbay.png";

import "./_header.scss";
import logo from "../../asset/pipgo.png";
import Modalv2 from "../../component/modal/Modalv2";
import SearchForm from "../../component/search/SearchForm";
import SeachOpenButton from "./SeachOpenButton";
import { ModalContext } from "../../context/ModalContext";
import { SearchContext } from "../../context/SearchContext";
import { BusinessBox } from "../../component/header/business-box/BusinessBox";
import useWindowSize from "../../hooks/useWindowSize";
import FavoriteHeader from "../../component/header/favorite/FavoriteHeader";
import { getDataUser } from "../../services/auth";


interface HeaderType {
  isInHomePage?: boolean;
}

interface UserType {
  _id: string,
  avatarUrl: string,
  email: string,
  name: string,
  phone: string,
  role: string
}

const Header = ({ isInHomePage = false }: HeaderType) => {
  const [form] = Form.useForm()
  const [isVisible, setVisible] = useState(false);
  const location = useLocation();
  const screen = useWindowSize();
  const { modalCtx, modalDispatch } = useContext(ModalContext);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [data, setData] = useState<UserType>()

  const profileRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getDataUser();
        if (res.status === 1) {
          setData(res.user)
        }
      } catch (e) {
        console.log(e)
      }
    }
    getUser()
  }, []);


  const handleMenuClick = (path: string) => {

    if (path === "/datxe") {
      window.open("https://info.pippip.vn/#mobile-link", "_blank");
    } else if (path === "/vemaybay") {
      window.open("https://www.senbay.vn/", "_blank");
    } else if (path === "/login") {
      if (isLoggedIn) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
      navigate(path);
      window.location.reload()
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);


  const menu = (
    <Menu onClick={({ key }) => handleMenuClick(key)} className="menu">
      <Menu.Item key="/vemaybay" className="menu-item">
        <img src={senbay} alt="pip" />
        <span>Vé máy bay</span>
      </Menu.Item>
      <Menu.Item key="/datxe" className="menu-item">
        <img src={pippip} alt="pip" />
        <span>Đặt xe ô tô</span>
      </Menu.Item>
      <Menu.Item key="/favorite" className="menu-item">
        <FolderOutlined className="menu-item-icon" />
        <span>Xem lại</span>
      </Menu.Item>
      {isLoggedIn && (
        <Menu.Item key="/home" className="menu-item">
          <UserOutlined className="menu-item-icon" />
          <span>Home</span>
        </Menu.Item>
      )}
      {isLoggedIn && (
        <Menu.Item key="/profile" className="menu-item">
          <UserOutlined className="menu-item-icon" />
          <span>Profile</span>
        </Menu.Item>
      )}
      <Menu.Item key="/login" className="menu-item">
        <LoginOutlined className="menu-item-icon" />
        {isLoggedIn ? <span>Logout</span> : <span>Login</span>}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setVisible(true);
    if (location.pathname === "/" || location.pathname.includes("/chat")) {
      setVisible(false);
    }
  }, [location.pathname]);

  const handleOpenFormModal = () => {
    modalDispatch({ type: "openModal" });
  };

  const handleCloseFormModal = () => {
    modalDispatch({ type: "closeModal" });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    navigate("/login")
    window.location.reload()
  }

  const handleClickOutside = (event: any) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setShowProfile(false);
    }
  };

  useEffect(() => {
    if (showProfile) {
      window.addEventListener('click', handleClickOutside);
    } else {
      window.removeEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showProfile]);

  return (
    <>
      {modalCtx.isVisibleOnMobile && (
        <Modalv2 classModal="modal">
          <IoCloseOutline className="btn-close" onClick={handleCloseFormModal}>
            Close
          </IoCloseOutline>
          <SearchForm className="form" />
        </Modalv2>
      )}

      <header
        id="nav"
        className="header"
        style={location.pathname === "/" ? { justifyContent: "flex-end" } : {}}
      >
        <div
          className="header-left"
          style={headerLeft(location.pathname)}
          onClick={(e) => {
            navigate("/");
          }}
        >
          <div className="header-img">
            <img src={logo} alt="Pipgo-logo" className="header-logo" />
          </div>
          <div className="brand">
            Pipgo
          </div>
        </div>

        {isVisible && <SeachOpenButton onClick={handleOpenFormModal} />}
        {isVisible && <SearchForm className="search-form" />}
        <div
          className="header-right"
          style={
            location.pathname === "/" || location.pathname.includes("/chat")
              ? { display: "flex" }
              : {}
          }
        >
          <FavoriteHeader />

          <div className="service business">
            <BusinessBox />
          </div>

          {!isLoggedIn && (
            <button
              className="login-button"
              onClick={() => navigate("/login")}
            >
              Đăng nhập
            </button>
          )}

          {isLoggedIn && (
            <>
              <div className="avatar" onClick={() => setShowProfile(!showProfile)} >
                <img src={data?.avatarUrl} alt="avatar" className="avt" />
              </div>
              {showProfile && (
                <div className="profile" ref={profileRef}>
                  <div className="info"
                    onClick={() => {
                      navigate('/home')
                      setShowProfile(false)
                    }}
                  >
                    <p>Home</p>
                    <UserOutlined />
                  </div>
                  <div className="info"
                    onClick={() => {
                      navigate("/profile")
                      setShowProfile(false)
                    }}
                  >
                    <p>Profile</p>
                    <UserOutlined />
                  </div>
                  <div className="logout" onClick={() => handleLogout()}>
                    <p>Logout</p>
                    <ArrowRightOutlined />
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="header-right-1" onClick={() => setShowMenu(!showMenu)}>
          <Dropdown overlay={menu} placement="bottomLeft" open={showMenu}>
            {showMenu ? (
              <IoCloseOutline style={{ fontSize: "30px", width: "30px", height: "30px" }} />
            ) : (
              <MenuOutlined style={{ fontSize: "30px", width: "30px", height: "30px" }} />
            )}
          </Dropdown>
        </div>
      </header>
    </>
  );
};
export default Header;

const headerLeft = (path: string): CSSProperties => {
  // Adding media query..
  if (path.includes("/chat")) return { display: "flex" };
  switch (path) {
    case "/":
      return { display: "none" };
    case "/area":
      return {};
    default:
      return {};
  }
};
