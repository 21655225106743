import { AxiosConfig, AxiosConfigLogin } from "../config/AxiosConfig";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import { logOut } from 'path/to/logoutFunction'; 

const axios = AxiosConfig();
const axiosLogin = AxiosConfigLogin();

// export const login = async (data: object) => {
//   let api = `/auth-customer/login`;
//   try {
//     const res = await axios.post(api, data);
//     return res.data;
//   } catch (e: any) {
//     throw e.response.data;
//   }
// };
export const login = async (data: object) => {
  let api = `/auth-customer/login`;
  try {
    const res = await axios.post(api, data);
    return res.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export const register = async (data: object) => {
  let api = `/auth-customer/register`;
  try {
    const res = await axios.post(api, data);
    return res.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export const getDataUser = async () => {
  let api = `/auth-customer/profile`;
  try {
    const res = await axiosLogin.get(api);
    return res.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export const changeAvatar = async (id: string, data: object) => {
  let api = `/auth-customer/change-avatar/${id}`;
  console.log("check data: ", data);
  try {
    const res = await axiosLogin.put(api, data, {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    });
    return res.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export async function getToken(callback: () => void): Promise<void> {
  const axios = AxiosConfig();
  try {
    const res = await axios.post(`${process.env.REACT_APP_API}/auth/refresh-token`, {
      refreshToken: localStorage.getItem(`${process.env.REACT_APP_PREFIX_LOCAL}_refresh_token`),
    });

    localStorage.setItem(`${process.env.REACT_APP_PREFIX_LOCAL}_access_token`, res.data.accessToken);
    callback();
  } catch (err: any) {
    if (err.response) {
      if (err.response.status === 401 || err.response.status === 403) {
        Modal.warn({
          title: `Token expired`,
          content: `Please logged in again !`,
          centered: true,
          afterClose: logOut,
          // icon: <ExclamationCircleOutlined />,
        });
      }
    }
  }
  /*
  axios
    .post(`${process.env.REACT_APP_API}/api/auth/refresh-token`, {
      refreshToken: localStorage.getItem(`${process.env.REACT_APP_PREFIX_LOCAL}_refresh_token`),
    })
    .then((res) => {
      localStorage.setItem(
        `${process.env.REACT_APP_PREFIX_LOCAL}_access_token`,
        res.data.accessToken,
      )
      callback()
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          logOut()
        }
      }
    }) */
}

export function logOut(): void {
  localStorage.removeItem(`${process.env.REACT_APP_PREFIX_LOCAL}_user`);
  localStorage.removeItem(`${process.env.REACT_APP_PREFIX_LOCAL}_access_token`);
  localStorage.removeItem(`${process.env.REACT_APP_PREFIX_LOCAL}_refresh_token`);
  localStorage.removeItem(`persist:root`);
  window.location.href = '/login';
}

