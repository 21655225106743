import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

const Hotel = React.lazy(() => import("../view/hotel/Hotel"));
const HotelDetails = React.lazy(() => import("../view/hotel/HotelDetail"));
const HotelAdmin = React.lazy(() => import("../view/hotel/HotelAdmin"));
const RestaAdmin = React.lazy(() => import("../view/restaurant/RestaAdmin"));

function HotelRoutes() {
    return (
        <Routes>
            <Route path="/hotel" element={<Hotel />} />
            <Route path="/hotel/:hotelId" element={<HotelDetails />} />
            <Route path="/hot/:id" element={<HotelAdmin />} />

        </Routes>
    );
}

export default HotelRoutes;
