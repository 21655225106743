import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

const Restaurant = React.lazy(() => import("../view/restaurant/Restaurant"));
const RestaurantDetails = React.lazy(() => import("../view/restaurant/RestaurantDetail"));
const RestaAdmin = React.lazy(() => import("../view/restaurant/RestaAdmin"));

function RestaurantRoutes() {
    return (
        <Routes>
            <Route path="/restaurant" element={<Restaurant />} />
            <Route path="/restaurant/:restaurantId" element={<RestaurantDetails />} />
            <Route path="/resta/:id" element={<RestaAdmin />} />

        </Routes>
    );
}

export default RestaurantRoutes;
